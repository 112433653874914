














































































































































































































































































































































































































/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
import CameraCapture from "@/components/CameraCapture/CameraCapture.vue";

import { getAPI } from "@/api/axios-base";
import { notifyError } from "@/components/Notification";
import { apiBaseUrl } from "@/enviorment";
import axios from "axios";
import moment from "moment";
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import AllImages from "@/components/Images/AllImages.vue";

export default Vue.extend({
  components: { CameraCapture, AllImages },
  name: "patient-personal-details",
  props: {
    patient: Object,
  },
  data() {
    return {
      loading: true,
      photoId: "",
      patientPhoto: new Blob(),
      uploadAs: null,
      uploadingPatientPhoto: false,
    };
  },
  computed: {
    ...mapGetters(["getCoordinatorN", "getSellerN"]),
    birthDate(): string {
      const date = moment(this.patient.birthDate);

      return date.format("MM/DD/YYYY");
    },
    havePhotoId(): boolean {
      const havePhotoId =
        (this.patientPhoto instanceof Blob && this.patientPhoto.size > 0) ||
        (this.patientPhoto instanceof File && this.patientPhoto.size > 0);

      return havePhotoId;
    },
  },
  watch: {
    procedure() {
      this.getPhoto();
    },
  },
  mounted() {
    this.getPhoto();
  },
  methods: {
    ...mapActions("crmMedicFormModule", ["getPhotoId"]),

    getPhone(phone: string) {
      if (phone[0] == "+") {
        return phone;
      }
      const pho = "+1" + phone;
      return pho;
    },

    async getPhoto() {
      this.loading = true;
      const patientdata = (
        await getAPI(`/patient/getPatient/${this.$route.params.uuid}`)
      ).data;

      const token = this.$store.state.accessToken;
      const auth =
        token != undefined && token != null && token != ""
          ? {
              Accept: "image/jpg",
              Authorization: `Bearer ${token}`,
            }
          : {
              Accept: "image/jpg",
            };

      if (patientdata.avatar != null) {
        axios({
          method: "get",
          url: `${apiBaseUrl}/patient/downloadAvatar/${this.patient.uuid}`,
          responseType: "blob",
          headers: auth,
        })
          .then((response) => {
            const blob = new Blob([response.data]);
            let reader = new FileReader();

            reader.onloadend = () => {
              let base64data = reader.result;

              (this as any).photoId = base64data;
            };
            reader.readAsDataURL(blob);
            this.loading = false;
          })
          .catch(() => {
            this.patientPhoto = new Blob();
            this.loading = false;
            // notificationError();
          });
        this.loading = false;
      } else {
        this.loading = false;
      }
    },
    onFileUpload(file: File) {
      if (file == null) {
        return;
      }
      const { name } = file;

      if (
        !name.includes(".png") &&
        !name.includes(".jpg") &&
        !name.includes(".jpeg")
      ) {
        notifyError(
          "The image format is not allowed, select <b>.png</b>, <b>.jpg</b> or <b>.jpeg</b>"
        );
        this.patientPhoto = new Blob();
      }
    },
    async uploadPatientPhoto(): Promise<any> {
      const formDataPhotoId = new FormData();

      this.uploadingPatientPhoto = true;
      formDataPhotoId.append(
        "photo",
        this.patientPhoto,
        `photo-id-${this.patient.uuid}`
      );

      await getAPI({
        method: "post",
        url: `/patient/uploadPatientAvatar/${this.patient.uuid}`,
        data: formDataPhotoId,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(() => {
          if ((this as any).$refs.camera) {
            (this as any).$refs.camera.closeCamera();
          }
          this.uploadingPatientPhoto = false;
          this.getPhoto();
        })
        .catch((error) => {
          this.uploadingPatientPhoto = false;

          let mess = error.response.data.message;

          if (mess.includes("[")) {
            mess = mess.replace("[", "");
            mess = mess.replace("]", "");
          }
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    bmi() {
      if (this.patient.weight == 0 && this.patient.height == 0) return 0;

      const bmi =
        (this.patient.weight /
          ((this.patient.height * 12 + this.patient.heightInches) *
            (this.patient.height * 12 + this.patient.heightInches))) *
        703;

      return Number(bmi).toFixed(2);
    },
  },
});
